import axios, { Axios, AxiosResponse } from "axios";

import { USER_NAMESPACE } from "@/components/library/document-permissions/constants";

class KetoClient {
  private apiClient: Axios;
  constructor() {
    const baseURL = process.env.KETO_API_URL;
    this.apiClient = axios.create({
      baseURL: baseURL,
      headers: {
        Authorization: `Bearer ${process.env.KETO_API_KEY}`,
      },
    });
  }

  // Creates a relationship between the given subject and object with the given relation.
  // Throws an error if the request fails.
  public async create_relationship(
    namespace: string,
    object: string,
    relation: string,
    subjectSetNamespace: string,
    subjectSetObject: string,
  ): Promise<any> {
    return await this.apiClient
      .put(`/admin/relation-tuples`, {
        namespace: namespace,
        relation: relation,
        object: object,
        subject_set: {
          namespace: subjectSetNamespace,
          object: subjectSetObject,
          relation: "",
        },
      })
      .then((res) => res?.data)
      .catch((err) => {
        console.log("create relationship error: ", err);
      });
  }

  // Checks if the given subject has the given permission on the given object.
  // Throws an error if the request fails.
  public async check_permission(
    namespace: string,
    object: string,
    relation: string,
    subjectId: string,
  ): Promise<any> {
    return await this.apiClient
      .post(`/relation-tuples/check`, {
        namespace: namespace,
        relation: relation,
        object: object,
        subject_set: {
          namespace: USER_NAMESPACE,
          object: subjectId,
          relation: "",
        },
      })
      .then((res) => res?.data)
      .catch((err) => {
        if (
          err.response.status === 403 &&
          err.response?.data.allowed === false
        ) {
          return {
            allowed: false,
          };
        }
        throw new Error(err.response?.data.message);
      });
  }

  // Deletes all relationships matching the given namespace and object.
  // Throws an error if the request fails.
  public async delete_all_relationships(
    namespace: string,
    object: string,
  ): Promise<any> {
    return await this.apiClient
      .delete(
        `/admin/relation-tuples?${new URLSearchParams({
          namespace: namespace,
          object: object,
        })}`,
      )
      .then((res) => res?.data)
      .catch((err) => {
        throw new Error(err.response?.data.message);
      });
  }

  // Deletes the permission matching the given namespace, object, relation, and subject.
  // Throws an error if the request fails.
  public async delete_permission(
    namespace: string,
    object: string,
    relation: string,
    subjectId: string,
  ): Promise<any> {
    return await this.apiClient
      .delete(
        `/admin/relation-tuples?${new URLSearchParams({
          namespace: namespace,
          relation: relation,
          object: object,
          "subject_set.namespace": USER_NAMESPACE,
          "subject_set.object": subjectId,
          "subject_set.relation": "",
        })}`,
      )
      .then((res) => res?.data)
      .catch((err) => {
        throw new Error(err.response?.data.message);
      });
  }

  // Returns all permissions matching the given namespace, object, and subject.
  // Throws an error if the request fails.
  public async get_permission(
    namespace: string,
    object: string,
    subjectId: string,
  ): Promise<any> {
    return await this.apiClient
      .get(
        `/relation-tuples?${new URLSearchParams({
          namespace: namespace,
          object: object,
          "subject_set.namespace": USER_NAMESPACE,
          "subject_set.object": subjectId,
          "subject_set.relation": "",
          page_size: "1000",
        })}`,
      )
      .then((res) => res?.data)
      .catch((err) => {
        throw new Error(err.response?.data.message);
      });
  }

  // Returns all relationships matching the given namespace, object, and relation.
  // Throws an error if the request fails.
  public async relationships(
    namespace: string,
    object: string,
    relationship?: string | null,
  ): Promise<any> {
    return await this.apiClient
      .get(
        `/relation-tuples?${new URLSearchParams({
          namespace: namespace,
          object: object,
          ...(relationship && { relation: relationship }),
          page_size: "1000",
        })}`,
      )
      .then((res) => res?.data)
      .catch((err) => {
        throw new Error(err.response?.data.message);
      });
  }

  // Returns all relationships for a subject in namespace. E.g. get all
  // documents in Document namespace that a user has any relation to
  public async relationshipsForSubject(
    subjectSetNamespace: string,
    subjectSetObject: string,
    namespace: string,
  ): Promise<any> {
    return await this.apiClient
      .get(
        `/relation-tuples?${new URLSearchParams({
          "subject_set.namespace": subjectSetNamespace,
          "subject_set.object": subjectSetObject,
          "subject_set.relation": "",
          namespace: namespace,
          page_size: "1000",
        })}`,
      )
      .then((res) => res?.data)
      .catch((err) => {
        throw new Error(err.response?.data.message);
      });
  }

  public async patchRelationships(relationships: Array<any>): Promise<any> {
    return await this.apiClient
      .patch(`/admin/relation-tuples`, relationships)
      .then((res) => res?.data)
      .catch((err) => {
        throw new Error(err.response?.data.message);
      });
  }
}

export default KetoClient;
